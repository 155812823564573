<template>
  <section class="seatmaps">
    <ul class="zoom-box">
      <li class="zoom-in" :class="scale==400?'font-gray':''" @click="zoomClick(1)"><PlusCircleFilled /></li>
      <li class="zoom">{{ scale }}%</li>
      <li class="zoom-out" :class="scale==100?'font-gray':''" @click="zoomClick(0)"><MinusCircleFilled /></li>
    </ul>
    <div ref="seatWrap" class="wrap" @mousewheel.prevent="rollImg">
      <div class="seatImg" :class="locationName" ref="seatImg" @mousedown.self="moveImg">
        <template v-for="(item,index) in seatData" :key="index">
          <Tooltip :title="item.code">
            <div
                class="icon-list"
                :class="[
                    type==='1'?'icon_seat_blue':'icon_seat_gray',
                    'seat-num-'+item.name,
                    item.safety==='0'&&item.status==='1'?type==='1'?'icon_seat_red':'icon_seat_green':'', //普通不能坐
                    item.safety==='1'&&item.status==='0'?'icon_seat_white_border':'', //隔离可坐
                    item.safety==='1'&&item.status==='1'?type==='1'?'icon_seat_red_border':'icon_seat_green_border':'', //隔离不能坐
                    checkSeat(item.name)
                ]"
                @click.stop="(e)=>{seatSel(e,item,seatData)}">
              <i class="icon-seat"></i>
            </div>
          </Tooltip>
        </template>
      </div>
    </div>
  </section>
</template>
<script>
import {
  defineComponent, onActivated,
  ref
} from "vue";
import {PlusCircleFilled,MinusCircleFilled} from "@ant-design/icons-vue"
import Tooltip from '@/components/Tooltip'

export default defineComponent( {
  name: "SeatMaps",
  components: {Tooltip, PlusCircleFilled,MinusCircleFilled},
  emits: ["get-seat-data"],
  props: {
    type:{//1蓝色icon  2灰色icon
      type: String,
      default: '1'
    },
    locationName: {
      type: String,
      default: 'xindi'
    },
    seatData: {
      type: Array,
      default: ()=>{
        return []
      }
    },
    selectedData: {
      type: Array,
      default: ()=>{
        return []
      }
    }
  },
  setup(props, ctx) {
    const scale = ref(100)
    const seatImg = ref(null)
    const rollImg = (e) => {
      let transform = seatImg.value.style.transform
      let zoom = transform.indexOf("scale") != -1 ? +transform.split("(")[1].split(")")[0] : 1
      zoom += e.wheelDelta / 600
      if (zoom >= 1 && zoom <= 4) {
        scale.value = (zoom * 100).toFixed(0)
        seatImg.value.style.transform = "scale(" + zoom + ")"
      }else if(zoom<1){
        scale.value = 100
        seatImg.value.style.transform = "scale(" + 1 + ")"
      }else if(zoom>4){
        scale.value = 400
        seatImg.value.style.transform = "scale(" + 4 + ")"
      }
    }
    const zoomClick = (flag) => {
      let transform = seatImg.value.style.transform
      let zoom = transform.indexOf("scale") != -1 ? +transform.split("(")[1].split(")")[0] : 1
      if(flag === 1){
        zoom += 0.5
        if (zoom >= 1 && zoom <= 4) {
          scale.value = (zoom * 100).toFixed(0)
          seatImg.value.style.transform = "scale(" + zoom + ")"
        }else{
          scale.value = 400
          seatImg.value.style.transform = "scale(" + 4 + ")"
        }
      }else{
        zoom -= 0.5
        if (zoom >= 1 && zoom <= 4) {
          scale.value = (zoom * 100).toFixed(0)
          seatImg.value.style.transform = "scale(" + zoom + ")"
        }else{
          scale.value = 100
          seatImg.value.style.transform = "scale(" + 1 + ")"
        }
      }
    }
    const seatWrap = ref(null)
    const moveImg = (e) => {
      let wrap = seatWrap.value
      let img = seatImg.value
      let x = e.pageX - img.offsetLeft
      let y = e.pageY - img.offsetTop
      // 添加鼠标移动事件
      wrap.addEventListener('mousemove', move)
      function move(e) {
        img.style.left = e.pageX - x + 'px'
        img.style.top = e.pageY - y + 'px'
      }
      // 添加鼠标抬起事件，鼠标抬起，将事件移除
      img.addEventListener('mouseup', () => {
        wrap.removeEventListener('mousemove', move)
      })
      // 鼠标离开父级元素，把事件移除
      /*wrap.addEventListener('mouseout', () => {
        wrap.removeEventListener('mousemove', move)
      })*/
    }
    const seatSel = (e,data,item) => {
      console.log('这个是',e,data,item)
      ctx.emit("get-seat-data",e,data)
    }
    const checkSeat = (data)=>{
      if(props.selectedData.find(item=>item.name===data&&item.safety==='0')){
        return 'icon_seat_green'
      }else if(props.selectedData.find(item=>item.name===data&&item.safety==='1')){
        return 'icon_seat_green_border'
      }
    }
    const actionFlag = ref()
    onActivated(()=>{
      actionFlag.value = sessionStorage.getItem('actionFlag')
    })
    return {
      actionFlag,
      seatImg,
      seatWrap,
      scale,
      rollImg,
      zoomClick,
      moveImg,
      seatSel,
      checkSeat
    };
  },
});
</script>
<style scoped lang="less">
.seatmaps{
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 9px;
  .zoom-box{
    position: absolute;
    top: 20px;
    left: 24px;
    font-size: 16px;
    z-index: 1000;
    li{
      display: block;
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      &.zoom-in,&.zoom-out{
        border-radius: 50%;
        color: #3A99F2;
        background: #fff;
        cursor: pointer;
      }
      &.zoom{
        margin: 10px 0;
        user-select: none;
      }
      &.font-gray{
        border-radius: 50%;
        color: #CCC;
        background: #fff;
        cursor: pointer;
      }
      :deep(svg){
        width: 32px;
        height: 32px;
      }
    }
  }
}
.wrap {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  .seatImg{
    position: absolute;
    cursor: move;
    .icon-list{
      position: relative;
      width: 16px;
      height: 16px;
      .icon-seat{
        display: block;
        width: 16px;
        height: 16px;
        &:before{
          display: block;
          content: '';
          width: 100%;
          height: 100%;
        }
      }
      &.icon_seat_blue{
        position: absolute;
        .icon-seat{
          &:before{
            background: url("../../../../assets/images/icon_seat_blue.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      &.icon_seat_gray{
        position: absolute;
        .icon-seat{
          &:before{
            background: url("../../../../assets/images/icon_seat_gray.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      &.icon_seat_white_border{
        .icon-seat{
          &:before{
            background: url("../../../../assets/images/icon_seat_white_border.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      &.icon_seat_green{
        .icon-seat{
          &:before{
            background: url("../../../../assets/images/icon_seat_green.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      &.icon_seat_red{
        .icon-seat{
          &:before{
            background: url("../../../../assets/images/icon_seat_red.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      &.icon_seat_green_border{
        .icon-seat{
          &:before{
            background: url("../../../../assets/images/icon_seat_green_border.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      &.icon_seat_red_border{
        .icon-seat{
          &:before{
            background: url("../../../../assets/images/icon_seat_red_border.svg") 100% no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }

  }
}
img {
  position: absolute;
  cursor: move;
}
</style>
<style scoped lang="less">
@import "~@/style/xindi56.less";
@import "~@/style/wanke05.less";
@import "~@/style/wanke04.less";
@import "~@/style/wanke03.less";
</style>
